.thought {
  margin-bottom: $s96;
  &:last-of-type {
    margin-bottom: 0;
  }
  text-decoration: none;

  &:hover {
    .thought__title {
      color: $color-blue;
    }
  }
}

.thought__date {
  @include helper-text();
}

.thought__title {
  @include header();
  margin-bottom: $s24;
  text-decoration: underline;
  text-decoration-color: $color-blue;
}