.project {
  padding-bottom: $s72;
  margin-bottom: $s72;

  @include media($tablet) {
    padding-bottom: $s96;
    margin-bottom: $s96;
  }

  text-decoration: none;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    .project__title {
      color: $color-blue;
    }
  }

  @include border-bottom();
}

.project__title {
  transition: color .2s ease-out;
  @include header();
  margin-bottom: $s24;
  text-decoration: underline;
  text-decoration-color: $color-blue;
}

.project__details {
  @include helper-text();
  p {
    margin-bottom: 0;
  }
}

.project__thumbnail {
  @include clearfix();
  img {
    @include round-photo();
    max-width: 100%;
  }
  
  margin-bottom: $s40;

  @include media($tablet) {
    margin-bottom: 0;
  }
}