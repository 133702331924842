// Colors
$color-black: #111111;
$color-dark-grey: #333333;
$color-grey: #757575;
$color-light-grey: #E5E5E5;
$color-white: #FFFFFF;
$color-blue: #007AFC;


// Spacing
@function rem($pixel-value) {
  $rem-base: 16;
  @return $pixel-value / $rem-base + rem;
}

$s4: rem(4);
$s8: rem(8);
$s12: rem(12);
$s16: rem(16);
$s20: rem(20);
$s24: rem(22);
$s32: rem(32);
$s40: rem(40);
$s48: rem(48);
$s52: rem(52);
$s64: rem(64);
$s72: rem(72);
$s84: rem(84);
$s96: rem(96);
$s136: rem(136);


// Typography
$font-text: "Roslindale", "Georgia", "Times New Roman", serif;
$font-display: "Roslindale-Deck-Narrow", "Georgia", "Times New Roman", serif;


// Images
$border-radius-picture: $s52;
$border-radius-picture-mobile: $s24;

// Media Queries
$mobile: 480px;
$tablet: 768px;
$desktop--small: 1000px;
$desktop--large: 1200px;

// Site
$site-max-width: 1600px;