.spacing-above-0 {
	margin-top: 0 !important;
}

.spacing-below-0 {
	margin-bottom: 0 !important;
}

.spacing-below-4 {
	margin-bottom: $s4 !important;
}

.spacing-below-8 {
	margin-bottom: $s8 !important;
}

.spacing-below-12 {
	margin-bottom: $s12 !important;
}

.spacing-below-16 {
	margin-bottom: $s16 !important;
}

.spacing-below-20 {
	margin-bottom: $s20 !important;
}

.spacing-below-24 {
	margin-bottom: $s24 !important;
}

.spacing-below-32 {
	margin-bottom: $s32 !important;
}

.spacing-below-40 {
	margin-bottom: $s40 !important;
}

.spacing-below-52 {
	margin-bottom: $s52 !important;
}

.spacing-below-72 {
	margin-bottom: $s72 !important;
}

.spacing-below-84 {
	margin-bottom: $s84 !important;
}

.spacing-below-96 {
	margin-bottom: $s96 !important;
}

.spacing-below-136 {
	margin-bottom: $s136 !important;
}