.main-footer {
	@include main-container();
	margin-top: $s96;
	margin-bottom: $s72;

	@include media($tablet) {
		margin-top: $s136;
		margin-bottom: $s96;
	}

}

.main-footer__subtitle {
	@include helper-text();
	font-style: italic;
}