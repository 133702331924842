@font-face {
  src: url('../fonts/Roslindale-TextRegular.woff2') format("woff2"),
       url('../fonts/Roslindale-TextRegular.woff') format("woff");
  font-family: 'Roslindale';
  font-style: normal;
  font-weight: normal;
}

@font-face {
  src: url('../fonts/Roslindale-TextItalic.woff2') format("woff2"),
       url('../fonts/Roslindale-TextItalic.woff') format("woff");
  font-family: 'Roslindale';
  font-style: italic;
  font-weight: normal;
}

@font-face {
  src: url('../fonts/Roslindale-TextBold.woff2') format("woff2"),
       url('../fonts/Roslindale-TextBold.woff') format("woff");
  font-family: 'Roslindale';
  font-style: normal;
  font-weight: bold;
}

@font-face {
  src: url('../fonts/Roslindale-DeckNarrowSemiBold.woff2') format("woff2"),
       url('../fonts/Roslindale-DeckNarrowSemiBold.woff') format("woff");
  font-family: 'Roslindale-Deck-Narrow';
  font-style: normal;
  font-weight: 600;
}