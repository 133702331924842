.main-header {
	@include main-container();
	margin-top: $s40;
	margin-bottom: $s72;

	@include media($tablet) {
		margin-top: $s96;
		margin-bottom: $s136;		
	}
}

.main-header__title a {
	@include body-text();
	text-decoration: none;
}

.main-header__subtitle {
	@include helper-text();
	font-style: italic;
}