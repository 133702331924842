@mixin body-text() {
  font-family: $font-text;
  letter-spacing: -.01em;
  font-size: $s20;
  line-height: $s32;

  @include media($desktop--small) {
    font-size: $s24;
    line-height: $s40;
  }
}

@mixin helper-text() {
  font-family: $font-text;
  letter-spacing: -.01em;
  font-size: $s20;
  line-height: $s32;
  color: $color-grey;

  @include media( $desktop--small) {
    font-size: $s24;
    line-height: $s40;
  }

  a {
    color: $color-grey;
  }
}

@mixin detail-text() {
  font-family: $font-text;
  letter-spacing: -.01em;
  font-size: $s12;
  line-height: $s20;
  color: $color-grey;

  @include media( $desktop--small) {
    font-size: $s16;
    line-height: $s24;
  }

  a {
    color: $color-grey;
  }
}

@mixin subtle-link() {
  transition: color .2s ease-out;
  color: $color-grey;
  text-decoration: none;
  text-decoration-color: $color-blue;
  &:hover {
    text-decoration: underline;
    color: $color-blue;
  }
}

@mixin header() {
  font-family: $font-text;
  letter-spacing: -.01em;
  font-weight: bold;
  font-size: $s20;
  line-height: $s32;

  @include media( $desktop--small) {
    font-size: $s24;
    line-height: $s40;
  }
}

@mixin large-header() {
  font-family: $font-display;
  letter-spacing: -.01em;
  font-weight: bold;
  font-size: $s32;
  line-height: $s48;

  @include media( $desktop--small) {
    font-size: $s40;
    line-height: $s64;
  }
}

body {
  @include body-text();
}

h1, h2, h3, h4, h5, h6 {
  @include header();
}

p {
  margin-bottom: $s32;
  @include media($desktop--small) {
    margin-bottom: $s40;
  }
}

.text--helper {
  @include helper-text();
}

.text--detail {
  @include detail-text();
}

a {
  transition: color .2s ease-out;
  color: $color-black;
  text-decoration-color: $color-blue;
  @media (prefers-color-scheme: dark) {
    color: $color-light-grey;
  }
}

p a, h1 a, h2 a, h3 a, li a {
  &:hover {
    color: $color-blue;
  }
}

.link--subtle {
  @include subtle-link();
}

.link--arrow {
  text-decoration: none;
  &:after {
    display: inline-block;
    transition: padding .3s ease-out;
    padding-left: .5em;
    content: ' →';

    a:hover & {
      color: $color-blue;
      padding-left: 1.5em;
    }
  }
}

.quote {
  margin-top: $s72;
  margin-bottom: $s72;

  @include media($desktop--small) {
    margin-top: $s96;
    margin-bottom: $s96;
  }

  blockquote {
    @include large-header();
    color: $color-dark-grey;
    @media (prefers-color-scheme: dark) {
      color: $color-light-grey;
    }
    p {
      &:last-of-type {
        margin-bottom: $s20;

        @include media( $desktop--small) {
          margin-bottom: $s24;
        }
      }
      &:before {
        content: '\201C';
      }
      &:after {
        content: '\201D';
      }
    }
  }

  .text--detail {
    @include helper-text();
    font-style: italic;
    margin-bottom: 0;
  }
}

.text--credits {
  @include detail-text();
  font-style: italic;

  &:before {
    content: "Photo credit: ";
  }
}

ul, ol {
  margin-top: $s40;
  margin-bottom: $s40;
}

ol {
    list-style: none;
    counter-reset: simple-number;
    li {
        counter-increment: simple-number;
        text-indent: -.7em;
        margin-left: 1.4em;
        margin-bottom: 1em;
        
        &:before {
            content: counter(simple-number);
            display: inline-block;
            width: .7em;
            font-weight: bold;
        }
    }
}

ul {
    li {
        text-indent: -.7em;
        margin-left: 1.4em;
        margin-bottom: 1em;

        list-style: none;
        &:before {
            content: '—';
            display: inline-block;
            width: .7em;
            font-weight: 400;
        }
    }
}

.list--no-style {
  list-style: none;
  margin: 0;
  li {
    text-indent: 0;
    margin-left: 0;
    margin-bottom: 0;
    &:before {
      display: none;
    }
  }
}

hr {
  margin-top: $s72;
  margin-bottom: $s72;
  @include media($desktop--small) {
    margin-top: $s96;
    margin-bottom: $s96;
  }
  border: 0;
  height: 2px;
  background-color: $color-light-grey;
  @media (prefers-color-scheme: dark) {
     background-color: $color-dark-grey;
  }
}