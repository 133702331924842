.about {

}

.about__contact {
	@include helper-text();

	a {
		@include subtle-link();
	}
}

.about__selected-works {
	@include media($tablet) {
		margin-bottom: 0;
	}
	@include helper-text();
	h2 {
		font-weight: normal;
		font-style: italic;
		@include helper-text();
	}
	ul {
		margin-top: 0;
		margin-bottom: $s40;
	}
	a {
		@include subtle-link();
	}
}

.about__photo {
	@include background-image-retina('../images/norris_hung', 'png', 192px, 246px);
	border-radius: $border-radius-picture;
	margin-bottom: $s40;
}

.about__bio {
	@include media($desktop--small) {
		margin-bottom: 0;
	}
}