.post__header {
  margin-bottom: $s96;
  @include media($desktop--small) {
    margin-bottom: $s136;
  }
  @include border-bottom();
}

.post__hero {
  margin-top: 0;
  margin-bottom: $s96;
  @include media($desktop--small) {
    margin-bottom: $s136;
  }
}


.post__title-container {
  position: relative;

  @include media($desktop--small) {
    border-bottom: 0px;
    &:before {
      position: absolute;
      top: $s20;
      left: 0;
      right: 2em;
      display: block;
      content: '';
      border-top: 1px solid $color-black;
      z-index: -1;
      @media (prefers-color-scheme: dark) {
        border-top: 1px solid $color-light-grey;
      }
    }
  }
}


.post__title {
  @include large-header();
  position: relative;
    @include media($desktop--small) {
        top: -$s8;
  display: inline-block;
  background-color: $color-white;
  padding-right: 1em;
    max-width: 12em;
    }

    @media (prefers-color-scheme: dark) {
      background-color: $color-black;
    }
}

.post__detail {
  @include helper-text();
  margin-bottom: 0;
  &:last-child {
    margin-bottom: $s32;
  }
}

.post__description {
  @include helper-text();
}

.post__info {
  margin-bottom: $s32;
  @include media($desktop--small) {
    margin-bottom: $s136;
  }
}


.post__content {
  border-bottom: 2px solid $color-light-grey;
  @media (prefers-color-scheme: dark) {
     border-bottom: 2px solid $color-dark-grey;
  }

  h2, h3 {
    @include large-header();
      margin-top: $s96;
      margin-bottom: $s32;
      @include media($desktop--small) {
        margin-top: $s136;
        margin-bottom: $s40;
      }
  }

  .row {
    margin-top: $s96;
    margin-bottom: $s96;
    @include media($desktop--small) {
      margin-top: $s136;
      margin-bottom: $s136;
    }
  }

  .row--no-spacing {
    margin-top: 0;
    @include media($desktop--small) {
      margin-top: 0;
    }
  }

  .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-12 {
    > *:first-child {
      margin-top: 0;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }

  .row {
    > *:first-child {
      margin-bottom: $s96;
      @include media($desktop--small) {
        margin-bottom: 0;
      }
    }
    >*:last-child {
      margin-bottom: 0;
    }
  }
}